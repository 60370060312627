export default function() {

	var limit = 14;
	var maxAdults = 14;
	var maxChildren = 6;
	var maxInfants = 6;

	$('.js-max-persons-adults:not(.js-max-persons-adults-done)').each(function() {
		var adults = $(this);
		var form = adults.closest('form');
		var children = form.find('.js-max-persons-children');
		var infants = form.find('.js-max-persons-infants');

		var _overLimit = function() {
			return parseInt(adults.val() + children.val(), 10) > limit;
		};

		var _setAdults = function() {
			if (_overLimit) {
				var diff = limit - adults.val();
				for (var i = 1; i <= maxChildren; i++) {
					if (i > diff) {
						children.find('option[value="' + i + '"]').remove();
					}
					else if (!children.find('option[value="' + i + '"]').length) {
						children.append('<option value="' + i + '">' + i + '</option>')
					}
				}
			}
			_setInfants();
		};

		var _setChildren = function() {
			if (_overLimit) {
				var diff = (limit - children.val());
				for (var j = 3; j <= maxAdults; j++) {
					if (j > diff) {
						adults.find('option[value="' + j + '"]').remove();
					}
					else if (!adults.find('option[value="' + j + '"]').length) {
						adults.append('<option value="' + j + '">' + j + '</option>')
					}
				}
			}
			_setInfants();
		};

		var _setInfants = function() {
			var _changed = infants.val() > 0 && infants.val() > adults.val();
			for (var k = 1; k <= maxInfants; k++) {
				if (k > adults.val()) {
					infants.find('option[value="' + k + '"]').remove();
				}
				else if (!infants.find('option[value="' + k + '"]').length) {
					infants.append('<option value="' + k + '">' + k + '</option>');
				}
			}
			if (_changed && (adults.val() <= maxInfants)) {
				infants.val(adults.val());
			}
		};

		// init
		_setAdults();

		// adults
		adults.off('change').on('change', function() {
			_setAdults();
		});

		// children
		children.off('change').on('change', function() {
			_setChildren();
		});

		// infants
		infants.off('change').on('change', function() {
			_setAdults();
		});

		// done
		adults.addClass('js-max-persons-adult-done');
	});

}
